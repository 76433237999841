<template>
  <div>
    <!-- pc -->
    <div class="school" v-if="$store.state.pc">
      <el-dialog title="验证" :visible.sync="vierdiong" width="30%">
        <span>这是一段信息</span>
        <el-input v-model="vier"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="vierdiong = false">取 消</el-button>
          <el-button type="primary" @click="curr">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="内容" :visible.sync="dialogVisible" width="80%">
        <iframe :src="vir_url" frameborder="0" width="100%" height="500px"></iframe>
        <div></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div class="head">
        <baseHradTwo :dataList="dataList.modular" @tabs="tabs" :currentIndex="currentIndex" :banner="banner"
          :title="$route.query.name"></baseHradTwo>
      </div>
      <div class="content">
        <div class="content_text">
          <div class="content_text_right">
            <div>
              <div class="title" v-for="(item, index) in pageContent" :key="index" @click="vie(item)">
                <span class="bor">{{ index + 1 + pageIndex * 5 - 5 }}</span><span class="title_text">{{ item.name
                  }}</span>
                <span class="title_right"></span>
              </div>
            </div>
            <div class="page" v-if="dataList.up && dataList.up.children">
              <el-pagination layout="prev, pager, next" :total="dataList.up.children.length"
                @current-change="page_current" :page-size="5">
              </el-pagination>
            </div>
          </div>
          <div class="content_text_left">
            <p v-if="dataList.up">
              {{ dataList.up.title }}
            </p>
            <img :src="dataList.up.img" />
          </div>
        </div>
        <div class="achievements">
          <div class="achievements_title" v-if="list_data.down.children && list_data.down.children.length > 0">
            <p>{{ dataList.down.title }}</p>
            <span></span>
          </div>
          <div class="achievements_img" v-if="dataList.down &&
      dataList.down.children &&
      dataList.down.children.length >= 4
      ">
            <el-carousel :interval="4000" type="card" height="300px">
              <el-carousel-item v-for="(item, index) in dataList.down.children" :key="index">
                <img :src="item.url" />
                <!-- <img src="../assets/微信图片_20211025024428.jpg"> -->
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="img_list" v-else>
            <ul v-if="dataList.down && dataList.down.children">
              <li v-for="item in dataList.down.children" :key="item">
                <img :src="item.url" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <basecopyRight></basecopyRight>
      </div>
    </div>
    <!-- mob -->
    <div class="school" v-else>
      <el-dialog title="验证" :visible.sync="vierdiong" width="80%">
        <span>这是一段信息</span>
        <el-input v-model="vier"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="vierdiong = false">取 消</el-button>
          <el-button type="primary" @click="curr">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="内容" :visible.sync="dialogVisible" width="80%">
        <iframe :src="vir_url" frameborder="0" width="100%" height="300px"></iframe>
        <div></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div class="head">
        <baseMobHeadTwo :dataList="dataList" :banner="banner" @mobopen="mobopen"></baseMobHeadTwo>
      </div>
      <div class="content">
        <div class="list">
          <p>{{ dataList.up.title }}</p>
          <div class="list_img">
            <div class="img_p">
              <p>{{ dataList.up.title }}</p>
            </div>

            <div class="list_text">
              <ul v-if="dataList && dataList.up && dataList.up.children">
                <li @click="vie(item)" v-for="(item, index) in dataList.up.children" :key="index">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="show">
          <p>{{ dataList.down.title }}</p>
          <div class="show_img">
            <ul v-if="dataList && dataList.down && dataList.down.children">
              <li @click="gocontent(item)" v-for="(item, index) in dataList.down.children" :key="index">
                <img :src="item.url" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="foot">
        <basecopyRight></basecopyRight>
      </div>
    </div>
  </div>
</template>

<script>
import { zhuanlan_index, banner_list, index_list, check } from "../api/api";
import baseHradTwo from "../components/Public/BaseheadTwo/baseheadTwo";
import baseMobHeadTwo from "../components/Public/baseMobHeadTwo/baseMobHeadTwo.vue";
import basecopyRight from "../components/Public/BasecopyRight/basecopyRight";
export default {
  components: {
    baseHradTwo,
    baseMobHeadTwo,
    basecopyRight,
  },
  data() {
    return {
      dialogVisible: false, //内容
      vier: "",
      vir_url: "",
      vierdiong: false, //验证
      vierdiongcontent: false, //内容
      banner: {},
      currentIndex: 0,
      dataList: {
        up: {
          title: "",
          chileren: [],
        },
        down: {
          title: "",
          children: [],
        },
      },
      pageIndex: 1,
      pageContent: [],
    };
  },
  created() {
    // this.color();
    this.getData();
  },
  methods: {
    // color() {

    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },
    vie(item) {
      this.project_id = item.id;
      if (item.pwd_type) {
        // 有密码
        this.vierdiong = true;
      } else {
        // 没密码
        this.vir_url = item.url;
        if (this.$store.state.mob) {
          localStorage.setItem("preview_pdf", item.url);
          this.$router.push({ path: "/pdf" })
        }
        else {
          this.dialogVisible = true
        }
      }
    },
    // 验证密码
    curr() {
      check({ file_id: this.project_id, password: this.vier }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.vir_url = res.data.url;
          this.vierdiong = false;
          localStorage.setItem("preview_pdf", res.data.url);
          this.$router.push({ path: "/pdf" })
        } else {
          this.$message.error("密码错误");
          this.vierdiong = false;
        }
      });
      this.vierdiong = false;
    },
    // 当前页
    page_current(index) {
      this.pageContent = [];
      this.pageIndex = index;
      let min = 0;
      let max = 4;
      min = index * 5 - 5;
      max = index * 5 - 1;
      this.dataList.up.children.forEach((item, index1) => {
        if (index1 >= min && index1 <= max) {
          this.pageContent.push(item);
        }
      });
      console.log(this.pageContent);
    },
    mobopen(value) {
      this.currentIndex = value - 1;
      if (this.currentIndex == 0) {
        this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
      } else {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}/contentTwo`,
          query: {
            modular_id: this.dataList.modular[this.currentIndex].id,
            project_id: this.dataList.modular[this.currentIndex].project_id,
            name: this.$route.query.name,
          },
        });
      }
      this.currentIndex = 0
    },
    // 这里index
    tabs(value, item) {
      this.currentIndex = value;
      if (value === 0) {
        if (this.$route.path == `/${localStorage.getItem("urlL")}/Two`) {
          return
        }
        this.$router.push({ path: `/${localStorage.getItem("urlL")}/Two`, query: this.$route.query });
      }
      // else if(value==1){
      //   this.$router.go(-1)
      // }
      else {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}/contentTwo`,
          query: {
            modular_id: item.id,
            project_id: item.project_id,
            name: this.$route.query.name,
          },
        });
      }
    },
    getData() {
      banner_list({ zl_url: localStorage.getItem("urlL") }).then((res) => {
        this.banner = res.data.data;
      }),
        index_list({ project_id: this.$route.query.project_id }).then((res) => {
          this.dataList = res.data.data;
          res.data.data.modular = [{ name: "首页" }, ...res.data.data.modular];
          this.dataList.up.children.forEach((item, index1) => {
            if (index1 >= 0 && index1 <= 4) {
              this.pageContent.push(item);
            }
          });
          console.log(this.pageContent, 555);
          console.log(this.dataList);
        });
    },
    gocontent(item) {
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/contentTwo`,
        query: {
          project_id: item.project_id,
          modular_id: item.id,
          name: this.$route.query.name,
        },
      });
    },
  },
  computed: {
    aaa() {
      return localStorage.getItem("urlL");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/style/default/default.scss";

.pc {
  width: 100%;

  .content {
    margin-top: 30px;
    background: white;
    width: 1400px;
    padding-bottom: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .content_text {
      padding-top: 30px;
      height: 200px;

      .content_text_left {
        width: 480px;
        height: 100%;
        float: left;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          color: white;

          transform: translate(-50%);
          margin: 0;
        }
      }

      .content_text_right {
        padding-left: 15px;
        position: relative;
        width: 900px;
        height: 100%;
        float: left;

        .title {
          height: 30px;
          padding: 0 50px 0 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 10px;
          font-size: 18px;

          .bor {
            color: $blue;
            font-weight: bold;
            padding: 0 15px;
            border-right: 1px solid $blue;
          }

          .title_text {
            color: #333333;
            font-size: 18px;
            padding-left: 15px;
            padding-right: 30px;

          }

          .title_right {
            float: right;
            margin-right: 30px;
            color: #999999;
          }
        }

        .page {
          position: absolute;
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;

          .el-pagination {
            display: inline-block;
          }
        }
      }
    }

    .achievements {
      margin-top: 30px;

      .achievements_title {
        text-align: center;
        font-size: 20px;
        color: #333333;

        p {
          margin: 0;
        }

        span {
          display: inline-block;
          width: 50px;
          height: 4px;
          background: $blue;
        }
      }

      .achievements_img {
        margin-top: 30px;
        height: 300px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .img_list {
        margin-top: 20px;
        width: 100%;

        ul {
          height: 200px;
          width: 100%;

          li {
            float: left;
            height: 100%;
            width: 330px;
            margin-right: 20px;

            &:nth-child(4) {
              margin-right: 0;
            }

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.mob {
  .school {
    .content {
      padding-top: 0.625rem;
      box-sizing: border-box;
      padding: 0 0.9375rem;
      margin-top: 1.25rem;

      .list {
        border-radius: 5px;
        min-height: 400px;

        p {
          font-size: 30px;
          margin: 0;
          color: $blue;
          margin-bottom: 10px;
        }

        .list_img {
          overflow: hidden;

          background: #f5f5f5;
          border-radius: 5px;
          min-height: 12.5rem;

          .img_p {
            background: url("../assets/项目页切图@2x.png") no-repeat;
            width: 100%;
            height: 9.375rem;
            background-size: 100% 100%;

            p {
              color: white;
              text-align: center;
              line-height: 9.375rem;
            }
          }

          .list_text {
            padding-top: 20px;

            ul {
              padding: 0;
              margin: 0;
              padding: 0 15px;

              li {
                font-size: 2.125rem;
                line-height: 3.75rem;
                height: 3.75rem;
                border-bottom: 1px solid #cccccc;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                &:hover {
                  cursor: pointer;
                }

                white-space:nowrap &:nth-child(1) {
                  border: 0;
                }
              }
            }
          }
        }
      }

      .show {
        margin-top: 2rem;

        p {
          margin: 0;
          font-size: 1.875rem;
          color: $blue;
        }

        .show_img {
          width: 100%;

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 0.625rem;
              border-radius: 5px;
              width: 48%;
              height: 11.25rem;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
